<template>
  <button class="text-blue" @click="swiper.slidePrev()">
    <svg width="40" height="12" viewBox="0 0 40 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6L10 11.7735V0.226497L0 6ZM40 5L9 5V7L40 7V5Z" class="fill-current"/>
    </svg>
  </button>
</template>

<script>
import {useSwiper} from "swiper/vue";

export default {
  name: "SwiperPrevButton",

  setup() {
    const swiper = useSwiper();
    return {
      swiper,
    };
  }
}
</script>