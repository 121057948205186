<template>
  <footer class="bg-black px-5 pt-10">
    <div class="container mx-auto text-white text-xs lg:text-sm">
      <div class="flex flex-col lg:grid lg:grid-cols-5 gap-5 lg:gap-10">
        <div class="col-span-2 flex flex-col">
          <router-link class="text-base font-medium" to="/">
            <img class="h-10 lg:h-[78px] xl:h-[100px]" src="@/assets/white_logo.svg" alt="Logo">
          </router-link>
          <a class="font-bold text-xs lg:text-base mt-10" href="tel:+380715195149">+7 (949) 501 05 55</a>
          <p class="mt-5">ООО «ЦТО «БАЗИС», ИНН 9303026967</p>
          <p>83001, Донецк, ул. Постышева, д. 60, этаж 3</p>

          <h3 class="text-base font-medium mt-5 lg:mt-14">График работы</h3>
          <p class="mt-2">пн-пт: 10:00-18:00</p>
          <p>сб-вс: выходные</p>
        </div>
        <div class="flex flex-col gap-2">
          <router-link class="text-base font-medium" to="/products">Каталог</router-link>
          <router-link class="text-base font-medium" to="/services">Услуги</router-link>
          <router-link class="text-base font-medium" to="/support">Поддержка</router-link>
          <router-link class="text-base font-medium" to="/news">Новости</router-link>
          <router-link class="text-base font-medium" to="/contacts">Контакты</router-link>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row gap-5 lg:gap-0 mt-10 lg:mt-24 justify-between font-light py-5 border-t border-white">
        <p>© 2014 – <span>{{ currentYear }}</span> Центр технического обслуживания “БАЗИС”</p>
        <a about="_blank" href="https://zk-digital.ru">Разработано в Z&K Digital Agency</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      currentYear: null
    };
  },
  mounted() {
    this.getCurrentYear();
  },
  methods: {
    getCurrentYear() {
      const now = new Date();
      this.currentYear = now.getFullYear();
    }
  }
};

</script>

<style scoped></style>