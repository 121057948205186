<template>
  <the-login :is-shown="showLogin" @close="toggleLogin"/>
  <div class="w-full border-dark-gray border-b lg:mb-1 px-5">
    <div class="container mx-auto">
      <div class="flex flex-col lg:flex-row justify-between py-2 gap-4 lg:gap-10">
        <a class="flex items-center gap-1 text-xs lg:text-lg font-bold" href="#">
          <img class="h-4" src="@/assets/icons/location.svg" alt="Location"> Донецк, ул. Постышева, 60
        </a>
        <a class="uppercase hidden lg:block text-xs lg:text-lg font-bold" href="#">+7 (949) 501 05 55 <span
            class="text-xs normal-case">(абонентский отдел)</span></a>
        <a class="uppercase hidden lg:block text-xs lg:text-lg font-bold" href="#">+7 (949) 501 33 35 <span
            class="text-xs normal-case">(торговый отдел)</span></a>
      </div>
    </div>
  </div>

  <header class="lg:pb-1 px-5 py-2 lg:py-0 bg-light-blue border-b border-b-dark-gray">
    <div class="container mx-auto">
      <div class="flex items-center justify-between lg:flex-row lg:gap-10 xl:gap-16 2xl:gap-32">
        <!-- Логотип слева -->
        <router-link to="/">
          <img class="h-12 lg:h-[78px] xl:h-[118px]" src="@/assets/logo.svg" alt="Logo">
        </router-link>

        <!-- Кнопка мобильного меню справа -->
        <div class="lg:hidden flex items-center">
          <button @click="toggleMobileMenu" class="p-2  text-blue">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>

        <div class="w-full hidden lg:flex flex-col gap-1">
          <!-- Desktop navigation (hidden on mobile) -->
          <nav class="hidden lg:flex justify-around relative">
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-xs xl:text-sm font-medium"
                         to="/products">Каталог</router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-xs xl:text-sm font-medium"
                         to="/services">Услуги</router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-xs xl:text-sm font-medium"
                         to="/support">Поддержка</router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-xs xl:text-sm font-medium"
                         to="/news">Новости</router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-xs xl:text-sm font-medium"
                         to="/contacts">Контакты</router-link>
          </nav>

          <!-- Desktop search form (hidden on mobile) -->
          <div class="hidden lg:flex mt-4 lg:mt-0">
            <form @submit.prevent="handleSearch" class="m-0 grow">
              <label for="desktop-search" class="mb-2 text-sm font-regular text-dark-gray sr-only">Поиск по сайту</label>
              <div class="relative">
                <input v-model="searchQuery" type="search" id="desktop-search"
                      class="block pl-5 pr-12 py-3 w-full text-xs lg:text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
                      placeholder="Поиск по сайту..." required>
                <button type="submit" class="absolute bg-blue top-[3px] right-[3px] h-9 w-9 lg:h-10 lg:w-10">
                  <svg class="w-5 h-5 mx-auto text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
                <button v-if="searchQuery" type="button" @click="clearSearch" class="absolute top-[3px] right-[50px] h-9 w-9 bg-red-500">
                  <svg class="w-5 h-5 mx-auto text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
       <div :class="{'hidden': !isMobileMenuOpen, 'block': isMobileMenuOpen}" class="lg:hidden mt-2">
       <!-- <div class="lg:hidden mt-2 border-t border-dark-gray"> -->
        <!-- Mobile navigation (visible only on mobile) -->
        <nav class="flex flex-col border-y border-dark-gray">
          <router-link class="uppercase py-3 text-center hover:bg-blue hover:text-white text-xs font-medium"
                        to="/products">Каталог</router-link>
          <router-link class="uppercase py-3 text-center hover:bg-blue hover:text-white text-xs font-medium"
                        to="/services">Услуги</router-link>
          <router-link class="uppercase py-3 text-center hover:bg-blue hover:text-white text-xs font-medium"
                        to="/support">Поддержка</router-link>
          <router-link class="uppercase py-3 text-center hover:bg-blue hover:text-white text-xs font-medium"
                        to="/news">Новости</router-link>
          <router-link class="uppercase py-3 text-center hover:bg-blue hover:text-white text-xs font-medium"
                        to="/contacts">Контакты</router-link>
        </nav>
        <!-- Mobile search form (visible only on mobile) -->
        <div v-if="isMobileMenuOpen" class="lg:hidden mt-2">
          <form @submit.prevent="handleSearch" class="m-0 grow">
            <label for="mobile-search" class="mb-2 text-sm font-regular text-dark-gray sr-only">Поиск по сайту</label>
            <div class="relative">
              <input v-model="searchQuery" type="search" id="mobile-search"
                    class="block pl-5 pr-12 py-3 w-full text-xs font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
                    placeholder="Поиск по сайту..." required>
              <button type="submit" class="absolute bg-blue top-[3px] right-[3px] h-9 w-9">
                <svg class="w-5 h-5 mx-auto text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
              <button v-if="searchQuery" type="button" @click="clearSearch" class="absolute top-[3px] right-[50px] h-9 w-9 bg-red-500">
                <svg class="w-5 h-5 mx-auto text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </form>
        </div>
       </div>
    </div>
  </header>
</template>

<script>
import TheLogin from "@/components/TheLogin";

export default {
  name: "TheHeader",
  components: { TheLogin },
  data() {
    return {
      showMenu: false,
      showLogin: false,
      isMobileMenuOpen: false,
      searchQuery: '' // добавляем переменную для хранения значения поиска
    }
  },
  mounted() {
    // Инициализация при загрузке страницы
    this.updateSearchQueryFromRoute();
  },
  watch: {
    // Отслеживание изменения маршрута и обновление searchQuery
    '$route.query.query': function(newQuery) {
      this.searchQuery = newQuery || ''; // Если параметра нет, очищаем поисковый запрос
    },
    // Добавляем наблюдатель для скрытия меню при смене маршрута
    '$route'() {
      this.isMobileMenuOpen = false; // Закрываем мобильное меню при переходе на другую страницу
    }
  },
  methods: {
    toggleNavbar() {
      this.showMenu = !this.showMenu;
    },
    toggleLogin() {
      this.showLogin = !this.showLogin;
      document.documentElement.style.overflow = this.showLogin ? 'hidden' : 'auto';
    },
    handleSearch() {
      if (this.searchQuery.trim()) {
        this.$router.push({ path: '/products', query: { query: this.searchQuery } });
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    clearSearch() {
      this.searchQuery = ''; // Очищаем поисковый запрос
      this.$router.push({ path: '/products' }); // Сбрасываем параметр query в URL
    },
    updateSearchQueryFromRoute() {
      // Обновляем поисковый запрос на основе параметра в маршруте
      if (this.$route.query.query) {
        this.searchQuery = this.$route.query.query;
      }
    }
  }
}
</script>
