<template>
  <router-link class="group hover:scale-105 p-2 lg:p-0 transition-all border lg:border-0 border-dark-gray group-hover:border-blue" :to="link">
    <div class="lg:w-44 lg:h-44 2xl:w-56 2xl:h-56 border-0 lg:border border-dark-gray grid content-center group-hover:border-blue">
      <img class="w-16 h-16 mx-auto" :src="require(`@/assets/icons/${icon}`)" alt="{{ content }}">
    </div>
    <p class="text-center mt-2 lg:mt-5 group-hover:text-blue text-xs lg:text-sm uppercase lg:w-44 2xl:w-56">{{ content }}</p>
  </router-link>
</template>

<script>
export default {
  name: "CategoryButton",
  props: ['link', 'content', 'icon']
}
</script>