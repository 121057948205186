<template>
  <div class="bg-white">
    <div class="container px-5 mx-auto py-5 lg:py-12">
      <h1 class="text-2xl lg:text-5xl">Новости и медиа</h1>
      <div class="flex flex-col divide-y divide-dark-gray border-y border-dark-gray mt-5 lg:mt-14">
        <div v-for="newsItem in newsData" :key="newsItem.id" class="flex flex-col lg:grid flex-col-reverse lg:grid-cols-2 lg:h-96 gap-5 lg:gap-10 py-5 lg:py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">{{ formatDate(newsItem.created_at) }}</p>
              <p class="text-xl lg:text-2xl font-medium">{{ newsItem.title }}</p>
              <p class="text-xs lg:text-sm text-dark-gray">{{ newsItem.description }}</p>
            </div>
            <router-link class="mt-5 lg:mt-0 underline-offset-4 hover:underline text-blue uppercase transition-all"
            :to="{ path: '/news/' + newsItem.id }">Читать далее</router-link>
          </div>
          <img class="lg:h-72 lg:w-full lg:object-cover" :src="newsItem.preview" :alt="newsItem.title">
        </div>
      </div>
      <vue-awesome-paginate
          ref="paginate"
          :total-items="totalNews"
          :items-per-page="5"
          :current-page="currentPage"
          :key="paginationKey"
          @click="fetchNews"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "NewsView",
  data() {
    return {
      newsData: [],
      totalNews: 0,
      currentPage: 1,
      paginationKey: 1
    };
  },
  mounted() {
    this.fetchNews();
  },
  methods: {
    fetchNews(page = 1) {
      axios.get(`https://api.bazis.zk-digital.ru/api/news?page=${page}&per_page=5`)
          .then(response => {
            this.newsData = response.data.data;
            this.totalNews = response.data.total;
            if (this.paginationKey === 1)
              this.paginationKey++;

            this.scrollToTop();
          })
          .catch(error => {
            console.error('Error fetching news:', error);
          });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
  margin-top: 48px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #005F8F;
  border: 1px solid #005F8F;
  color: white;
}

.active-page:hover {
  background-color: #005F8F;
}
</style>
