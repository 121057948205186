<template>
  <button class="text-dark-gray text-blue" @click="swiper.slideNext()">
    <svg width="40" height="12" viewBox="0 0 40 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40 6L30 0.2265L30 11.7735L40 6ZM-8.74228e-08 7L31 7L31 5L8.74228e-08 5L-8.74228e-08 7Z" class="fill-current"/>
    </svg>
  </button>
</template>

<script>
import {useSwiper} from "swiper/vue";

export default {
  name: "SwiperNextButton",

  setup() {
    const swiper = useSwiper();
    return {
      swiper,
    };
  }
}
</script>