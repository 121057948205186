<template>
  <div class="flex flex-col items-start">
    <p class="text-xs lg:text-sm font-semibold uppercase">{{ subtitle }}</p>
    <h3 class="text-blue text-lg lg:text-3xl 2xl:text-5xl leading-none font-medium uppercase mt-1 lg:mt-3 lg:mb-5 mb-2">{{ title }}</h3>
    <p class="text-xs lg:text-lg leading-tight font-medium">{{ description }}</p>
    <ul class="list-disc marker:text-blue list-inside mt-5 lg:mt-0 text-xs lg:text-base">
      <!-- Используем v-for для создания элементов списка из массива строк -->
      <li v-for="(item, index) in items" :key="index">{{ item }}</li>
    </ul>
    <small-button content="Узнать подробнее" link="/" styleClasses="px-3 lg:w-56 text-blue hover:bg-blue hover:text-white mt-5" @click="handleButtonClick"></small-button>
  </div>
</template>

<script>
import SmallButton from "@/components/buttons/SmallButton";

export default {
  name: "SwiperItem",
  components: {SmallButton},
  props: {
    title: String,
    subtitle: String,
    description: String,
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleButtonClick() {
      // Здесь можно добавить логику для обработки кнопки
      this.$router.push({ path: '/products', query: { query: this.title || '' } });
    }
  }
}
</script>

<style scoped>

</style>
