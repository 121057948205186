<template>
  <div class="bg-light-blue border-b border-b-dark-gray px-5 pb-5 lg:pb-0">
    <div class="container mx-auto flex flex-col flex-col-reverse lg:grid lg:grid-cols-5 lg:divide-x divide-dark-gray">
      <div class="lg:my-5 mt-5 col-span-3 lg:mr-5 lg:w-100">
        <swiper :modules="modules" :slides-per-view="1" :space-between="0" :pagination=pagination
          @slideChange="onSlideChange">
          <swiper-slide v-for="(slide, index) in slides" :key="index">
            <!-- Передача свойства items в SwiperItem -->
            <swiper-item :title="slide.title" :subtitle="slide.subtitle" :description="slide.description"
              :items="slide.items" />
          </swiper-slide>

          <div class="flex gap-10 justify-center lg:justify-normal mt-5 xl:mt-5">
            <SwiperPrevButton />
            <div class="swiper-pagination flex gap-2"></div>
            <SwiperNextButton />
          </div>
        </swiper>
      </div>
      <div class="col-span-2">
        <div class="flex h-full justify-center flex-col divide-y divide-dark-gray">
          <div class="lg:my-5 mt-5 lg:mt-0 lg:ml-5">
            <img class="h-36 lg:h-auto lg:max-h-80 w-full object-contain" v-if="onSlideChange" :src="this.currentImage"
              alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperItem from "@/components/swiper/SwiperItem";
import 'swiper/css';
import SwiperNextButton from "@/components/swiper/SwiperNextButton";
import SwiperPrevButton from "@/components/swiper/SwiperPrevButton";
import axios from "axios";
import { ref, onMounted } from 'vue';

export default {
  name: "HomeSwiper",
  components: {
    SwiperPrevButton,
    SwiperNextButton,
    SwiperItem,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const slides = ref([]);
    const currentImage = ref('');
    const currentSlideIndex = ref(0);

    const onSlideChange = (swiper) => {
      currentSlideIndex.value = swiper.activeIndex;
      currentImage.value = slides.value[currentSlideIndex.value]?.image || '';
    };

    onMounted(async () => {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/slides');
        slides.value = response.data.slides;
        currentImage.value = slides.value[currentSlideIndex.value]?.image || '';
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    });

    return {
      slides,
      currentImage,
      currentSlideIndex,
      onSlideChange,
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        renderBullet: function (index, className) {
          return '<span class="' + className + ' w-2 h-2 bg-dark-gray rounded-full cursor-pointer"></span>';
        }
      }
    };
  }
}
</script>

<style>
.swiper-pagination-bullet-active {
  background: rgb(0 95 143) !important;
}
</style>
