<template>
  <a
    class="bg-white after:block border after:pb-full flex justify-center items-center hover:text-blue hover:border-blue transition-all"
    :href="link" target="_blank">
    <div class="absolute">
      <div class="flex flex-col gap-16">
        <img class="w-72" :src="require(`@/assets/clients/${image}`)" alt="{{ content }}">
        <p class="uppercase text-center">{{ content }}</p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "ClientItemButton",
  props: ['content', 'image', 'link']
}
</script>

<style scoped>

</style>