<template>
  <HomeSwiper/>
  <div class="container px-5 mx-auto py-5 lg:py-12 xl:py-24">
    <div class="flex flex-col flex-col-reverse lg:grid grid-cols-3">
      <div class="flex flex-col font-medium">
        <h2 class="mt-5 lg:mt-0 text-xl xl:text-2xl">Продуктовые группы</h2>
        <div class="space-y-1 lg:space-y-4 mt-5 lg:mt-14 xl:mt-36">
          <div v-for="category in categories" :key="category.id" class="flex flex-col text-xs lg:text-md text-dark-gray">
            <router-link :to="`/products?category_id=${category.id}`" class="uppercase text-black hover:underline lg:text-sm transition-all">{{ category.name }}</router-link>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <h3 class="text-xl lg:text-3xl xl:text-5xl">Хиты продаж</h3>
        <div class="grid grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-5 mt-5 lg:mt-14">
          <div v-for="product in topProducts" :key="product.id">
            <product-item-button
              :content="product.name"
              :image="product.photos[0]"
              link="#"
              @click="handleButtonClick(product.name)"
            />
          </div>
        </div>
        <small-button text="Узнать подробнее" style-classes="text-center mt-5 xl:mt-14 w-100 lg:w-64 text-blue hover:bg-blue hover:text-white"
                      link="products"/>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="container px-5 mx-auto py-5 lg:py-12 xl:py-24">
      <div class="grid grid-cols-3">
        <div class="col-span-3">
          <h3 class="text-xl lg:text-5xl">Мы предоставляем <span class="text-blue">оборудование и ПО для автоматизации</span>
            различных сфер бизнеса</h3>
          <div class="grid justify-between xl:grid-cols-6 lg:grid-cols-4 grid-cols-2 gap-2 lg:gap-10 mt-5 lg:mt-14">
            <category-button
                content="Автоматизация розничной торговли"
                link="/products"
                icon="retail.svg"
            />
            <category-button
                content="Автоматизация кафе, ресторанов, баров"
                link="/products"
                icon="horeca.svg"
            />
            <category-button
                content="Автоматизация склада"
                link="/products"
                icon="stock.svg"
            />
            <category-button
                content="Видеонаблюдение"
                link="/services"
                icon="retail.svg"
            />
            <category-button
                content="Система Контроля Управления Доступом"
                link="/services"
                icon="retail.svg"
            />
            <category-button
                content="Техническая поддержка"
                link="/services"
                icon="retail.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mx-auto px-5 py-5 lg:py-12 xl:py-24 grid grid-cols-3">
    <div class="col-span-3">
      <h3 class="text-lg lg:text-5xl">Будьте в курсе <span class="text-blue">событий из жизни компании</span></h3>
      <div class="grid xl:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 divide-y xl:divide-none divide-dark-gray border-t border-b xl:border-t-0 border-dark-gray mt-5 lg:mt-14 pb-5 lg:pb-10">
        <div v-for="newsItem in news" :key="newsItem.id">
          <div class="grid grid-cols-2 xl:border-t xl:border-dark-gray gap-10 lg:pt-10 pt-5">
            <div class="flex flex-col justify-between">
              <div class="space-y-2">
                <p class="text-xs lg:text-sm font-medium text-dark-gray">{{ formatDate(newsItem.created_at) }}</p>
                <p class="text-lg lg:text-2xl font-medium">{{ newsItem.title }}</p>
                <p class="text-xs lg:text-sm text-dark-gray">{{ newsItem.description }}</p>
              </div>
              <router-link :to="`/news/${newsItem.id}`" class="underline-offset-4 hover:underline text-blue uppercase transition-all cursor-pointer text-xs lg:text-sm mt-5 lg:mt-0">Читать далее</router-link>
            </div>
            <img class="h-36 lg:h-72 w-full object-cover" :src="newsItem.preview" :alt="newsItem.title">
          </div>
        </div>
      </div>
      <div class="flex mt-5 lg:mt-14 gap-5">
        <small-button text="Все новости" :to="`/news`"
                      style-classes="text-white w-full lg:w-auto px-10 bg-blue hover:bg-white hover:text-blue"/>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSwiper from '@/components/swiper/HomeSwiper.vue';
import SmallButton from '@/components/buttons/SmallButton';
import CategoryButton from "@/components/buttons/CategoryButton";
import ProductItemButton from "@/components/buttons/ProductItemButton";
import axios from "axios";

export default {
  name: 'HomeView',
  components: {
    SmallButton,
    HomeSwiper,
    CategoryButton,
    ProductItemButton
  },
  data: () => ({
    topProducts: [],
    categories: [],
    news: [],
  }),
  async mounted() {
    await this.fetchTopProducts();
    await this.fetchCategories();
    await this.fetchNews();
  },
  methods: {
    async fetchTopProducts() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/products/top');
        this.topProducts = response.data.data;
        this.scrollToTop();
      } catch (error) {
        console.error('Ошибка при загрузке списка топ-товаров:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/categories');
        this.categories = response.data.categories.map(category => ({
          ...category,
          selected: false
        }));
        this.selectedCategory = this.categories[0].id;
      } catch (error) {
        console.error('Ошибка при загрузке списка категорий:', error);
      }
    },
    async fetchNews() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/news?per_page=2&sort_by=date_desc');
        this.news = response.data.data;
      } catch (error) {
        console.error('Ошибка при загрузке списка новостей:', error);
      }
    },
    handleButtonClick(name) {
      // Здесь можно добавить логику для обработки кнопки
      this.$router.push({ path: '/products', query: { query: name || '' } });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
