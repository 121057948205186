<template>
  <router-link
    class="bg-white after:block after:pb-full flex justify-center items-center p-2 lg:p-0 hover:outline outline-1 hover:scale-105 outline-blue hover:text-blue transition-all"
    :to="link">
    <div class="flex mx-auto">
      <div class="flex mx-auto flex-col gap-8">
        <img class="w-48 mx-auto" :src="image" alt="{{ content }}">
        <p class="uppercase lg:mx-5 text-xs lg:text-sm text-center">{{ content }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProductItemButton",
  props: ['content', 'image', 'link']
}
</script>

<style scoped>

</style>