<template>
  <div class="bg-white">
    <div class="container px-5 mx-auto pt-5 lg:pt-12">
      <h1 class="text-2xl lg:text-5xl">Контакты</h1>

      <div class="flex flex-col gap-2 lg:gap-5 mt-5 lg:mt-12">
        <h2 class="text-xl lg:text-2xl font-medium">Телефоны</h2>
        <p><span class="font-medium">Абонентский отдел: </span><a class="text-blue underline" href="tel:+38071">+7 (949)
            501 05 55</a></p>
        <p><span class="font-medium">Техническая поддержка: </span><a class="text-blue underline" href="tel:+38071">+7
            (949) 501 33 35</a></p>
      </div>
      <div class="flex flex-col gap-2 lg:gap-5 mt-5 lg:mt-12">
        <h2 class="text-xl lg:text-2xl font-medium">Обратная связь</h2>
        <form class="grid grid-cols-1 lg:grid-cols-2 gap-5" @submit.prevent="submitForm">
          <div>
            <label for="name" class="mb-2 text-sm font-regular text-dark-gray sr-only">Ваше имя</label>
            <input v-model="form.name" id="name"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Ваше имя*" required>
          </div>
          <div>
            <label for="company" class="mb-2 text-sm font-regular text-dark-gray sr-only">Компания</label>
            <input v-model="form.company" id="company"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Компания">
          </div>
          <div>
            <label for="phone" class="mb-2 text-sm font-regular text-dark-gray sr-only">Номер телефона</label>
            <input v-model="form.phone" id="phone"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Номер телефона*" required>
          </div>
          <textarea v-model="form.question"
            class="h-20 lg:col-span-2 block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
            type="text" name="question" id="question" placeholder="Ваш вопрос" required></textarea>
          <button class="uppercase text-white bg-blue py-4 line px-9 text-sm content-center w-full max-h-[52px] lg:w-80"
            type="submit">
            Отправить
          </button>
          <div id="captcha-container"></div>
        </form>
      </div>
      <div class="flex flex-col gap-2 lg:gap-5 mt-5 lg:mt-12">
        <h2 class="text-xl lg:text-2xl font-medium">Адрес офиса</h2>
        <p><span class="font-medium">Адрес: </span>83001, ДНР, г. Донецк, ул. Постышева, д. 60, этаж 3</p>
        <p><span class="font-medium">Режим работы: </span>10:00 - 17:00 (кроме выходных и праздничных дней)</p>
      </div>
    </div>
    <iframe class="mt-5 mb-12"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A3ca9147f2313aad6b92bc9dee0fe837b6a7d01dd67f8dcc31327bf04db2f616d&amp;source=constructor"
      width="100%" height="450" frameborder="0"></iframe>
  </div>
</template>

<script>

export default {
  name: "ContactsView",
  data() {
    return {
      form: {
        name: '',
        company: '',
        phone: '',
        question: ''
      },
      captchaToken: ''
    };
  },
  mounted() {
    // Инициализация капчи после монтирования компонента
    window.smartCaptcha.render('captcha-container', {
      sitekey: 'ysc1_Sr4GcXmxP1Z6pzps1Rpai6pLynLz6rUfdjY0P5f24b1f02cd',
      callback: this.onCaptchaVerified  // Функция обратного вызова при успешной проверке капчи
    });
  },
  methods: {
    onCaptchaVerified(token) {
      this.captchaToken = token;  // Сохраняем токен капчи
    },
    async submitForm() {
      if (!this.captchaToken) {
        alert('Пожалуйста, пройдите проверку капчи!');
        return;
      }
      try {
        const response = await fetch('https://api.bazis.zk-digital.ru/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ ...this.form, captcha: this.captchaToken }),
        });
        const result = await response.json();
        if (result.success) {
          alert('Ваше сообщение отправлено!');
          this.form = {
            name: '',
            company: '',
            phone: '',
            question: ''
          }; // Очищаем поля формы
        } else {
          alert('Ошибка отправки, попробуйте позже.');
        }
      } catch (error) {
        console.error('Ошибка отправки формы:', error);
        alert('Произошла ошибка, попробуйте снова.');
      }
    }
  }
};
</script>

<style scoped></style>